import { Link } from 'gatsby'
import Routes from 'Routes'

import Icon from 'rv-components-onze/src/Icon'
import navList from 'constants/menuList'
import Condition from 'components/Condition'

import enhancer from './hooks'
import { BASE_URL } from 'gatsby-env-variables'

const { home } = Routes

const transitionColor = {
  primary: 'is-transparent--primary',
  white: 'is-transparent--white',
}

const Header = ({
  onClickMenu,
  goTo,
  menuActive,
  isPageOnTop,
  menuIsOpened,
  hasTransition,
  onClickSidebar,
  transitionFontColor = 'white',
  isSuccessPage,
}) => {
  const pageOnTop = isPageOnTop && hasTransition ? 'is-transparent' : ''
  const selectedTransitionColor = transitionColor[transitionFontColor]

  return (
    <header className={`header ${pageOnTop} ${selectedTransitionColor}`}>
      <div className="container header__content">
        <Icon onClick={onClickMenu} iconId="hamburguer-icon" />

        <Link to={home.path} aria-label="Logo Onze">
          <Icon className="header__logo" iconId="onze-logo" />
        </Link>

        <div className="header__block">
          <nav className="header__nav nav">
            <ul className="nav__items">
              {navList.map((item, index) => (
                <li
                  key={`nav-${index}-${item.path}`}
                  className="nav__menu-item">
                  {item.subMenu ? (
                    <>
                      <Link
                        className={`nav__item ${
                          menuActive === item.path ? 'nav__item--active' : ''
                        }`}
                        to={item.path}>
                        {item.name}
                      </Link>
                      <ul className="nav__sub-menu">
                        {item.subMenu.map((subItem, index) => (
                          <li className="nav__sub-menu-item">
                            <Link
                              className={`nav__item ${
                                menuActive === subItem.path
                                  ? 'nav__item--active'
                                  : ''
                              }`}
                              to={subItem.path}>
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : item.path ? (
                    <Link
                      className={`nav__item ${
                        menuActive === item.path ? 'nav__item--active' : ''
                      }`}
                      to={item.path}>
                      {item.name}
                    </Link>
                  ) : (
                    <a
                      className={`nav__item ${
                        menuActive === item.path ? 'nav__item--active' : ''
                      }`}
                      href={item.href}
                      target="_blank">
                      {item.name}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          <div className="header__buttons buttons">
            <a
              href={`${BASE_URL}painel/login/`}
              title="Ir para tela de login"
              className="nav__item">
              Login
            </a>

            <Condition condition={!isSuccessPage}>
              <button
                className="buttons__button buttons__button--border"
                onClick={onClickSidebar}>
                Quero contratar
              </button>
            </Condition>
          </div>
        </div>
      </div>
    </header>
  )
}

export default enhancer(Header)
