module.exports = {
  home: {
    path: '/',
    component: 'src/pages/Home/index.js',
  },
  homeInstitutional: {
    path: '/home-institutional',
    component: 'src/pages/Home/index.js',
  },
  privacyPolice: {
    path: '/politica-de-privacidade',
    component: 'src/pages/PrivacyPolice/index.js',
  },
  b2b: {
    path: '/empresas',
    component: 'src/pages/B2B/index.js',
  },
  formHeroLanding: {
    path: '/landing-conversao-1/',
    component: 'src/pages/FormHeroLanding/index.js',
  },
  formHeroLandingTwo: {
    path: '/landing-conversao-2/',
    component: 'src/pages/FormHeroLandingTwo/index.js',
  },
  designSystem: {
    path: '/design-system/',
    component: 'src/pages/DesignSystem/index.js',
  },
  financialHealth: {
    path: '/saude-financeira/',
    component: 'src/pages/FinancialHealth/index.js',
  },
  success: {
    path: '/sucesso/',
    component: 'src/pages/FormSuccess/index.js',
  },
  management: {
    path: '/carteira/',
    component: 'src/pages/Management/index.js',
  },
  yourCompany: {
    path: '/sua-empresa/',
    component: 'src/pages/ForYourCompany/index.js',
  },
  safety: {
    path: '/seguranca/',
    component: 'src/pages/Safety/index.js',
  },
  about: {
    path: '/quem-somos/',
    component: 'src/pages/About/index.js',
  },
  aboutOnze: {
    path: '/onze-gestora/',
    component: 'src/pages/OnzeManager/index.js',
  },
}
