import { useSelector, useDispatch } from 'react-redux'
import { toggleMenu } from 'ducks/ui'
import { useEffect, useState } from 'react'
import isClient from 'utils/isClient'
import { isMobile } from 'utils/getDevice'
import { home, success } from '../Routes'
import R from 'ramda'

const handleMenu = () => {
  const menuIsOpened = useSelector(state => state.ui.menuIsOpened)
  const dispatch = useDispatch()

  const [menuActive, setMenuActive] = useState('')
  const [isPageOnTop, setIsPageOnTop] = useState(true)

  isClient() && document.addEventListener('scroll', () => {
    const pagePosition = window.pageYOffset

    if (pagePosition > 0) {
      setIsPageOnTop(false)
    } else {
      setIsPageOnTop(true)
    }
  })

  const onClickMenu = () => {
    dispatch(toggleMenu(!menuIsOpened))
  }

  const getId = hashId => R.pipe(
    R.split('#'),
    R.pathOr('', [1])
  )(hashId)

  const goTo = (e) => {
    const id = e.id || e.currentTarget.dataset.path
    const el = document.getElementById(id)

    if (isClient()) {
      const currentPath = window.location.pathname
      window.location.hash = id

      if (el && currentPath === home.path) {
        const gap = isMobile() ? 58 : 78
        const scrollTop = el.offsetTop - gap

        window.scroll({
          top: scrollTop,
          behavior: 'smooth'
        })

        setMenuActive(id)
        dispatch(toggleMenu(!menuIsOpened))
      } else {
        window.location = `${home.path}#${id}`
      }
    }
  }

  useEffect(() => {
    if (isClient()) {
      // const hash = window.location.hash
      // const id = getId(hash)
      // !!id && setTimeout(() => goTo({ id }), 500)

      const currentPathName = window.location.pathname
      setMenuActive(currentPathName)
    }
  }, [])

  const isSuccessPage = isClient() && window.location.pathname === success.path

  return { menuIsOpened, onClickMenu, goTo, menuActive, isPageOnTop, isSuccessPage }
}

export default handleMenu
