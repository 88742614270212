import Routes from 'Routes'

const { financialHealth, management, yourCompany, about, aboutOnze } = Routes

const menuList = [
  {
    name: 'Quem somos',
    subMenu: [
      {
        name: 'Onze',
        path: about.path,
      },
      {
        name: 'Onze Gestora',
        path: aboutOnze.path,
      }
    ]
  },
  {
    name: 'Previdência privada',
    path: yourCompany.path,
  },
  {
    name: 'Carteira',
    path: management.path,
  },
  {
    name: 'Saúde financeira',
    path: financialHealth.path,
  },
  {
    name: 'Blog',
    href: 'https://www.onze.com.br/blog/',
  },
]

export default menuList
