import { useSelector, useDispatch } from 'react-redux'
import { toggleSidebar } from 'ducks/ui'
import segmentDispatcher from 'utils/segment/dispatcher'

const toggleSidebarStore = () => {
  const sidebarIsOpened = useSelector(store => store.ui.sidebarIsOpened)
  const dispatch = useDispatch()

  const onClickSidebar = () => {
    const openSidebar = !sidebarIsOpened

    dispatch(toggleSidebar(openSidebar))

    if(openSidebar) {
      segmentDispatcher('corporateLeadStarted')
    }
  }
  
  return { onClickSidebar }
}

export default toggleSidebarStore
